import React from 'react';
import { graphql } from 'gatsby';
import { PostSnapshot } from '../components/PostSnapshot';
import { Layout } from '../components/Layout';
import { BlogHeader } from '../elements/BlogHeader';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${tag} (${totalCount})`;

  return (
    <Layout>
      <BlogHeader title={tagHeader} linkTo='/blog' linkString='Back to Blog' />
      {edges.map(({ node: { fields, frontmatter, id, excerpt } }) => (
        <PostSnapshot
          slug={fields.slug}
          title={frontmatter.title}
          date={frontmatter.date}
          id={id}
        >
          <p style={{ maxWidth: '90%' }}>{excerpt}</p>
        </PostSnapshot>
      ))}
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 8
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 125)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD-MMM-YY")
          }
        }
      }
    }
  }
`;
